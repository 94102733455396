<template>
  <div>
      <div class="clearfix table-tools">
        <div class="buttons"></div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-radio-group :value="searchData.shsz" @change="handleButChange">
                    <a-radio-button value="sh">
                    主板
                    </a-radio-button>
                    <a-radio-button value="sz">
                    创业
                    </a-radio-button>
                </a-radio-group>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>代码</span>
                    </template>
                    <a-input v-model.trim="searchData.stock_code" allowClear placeholder="代码" style="width: 180px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                    <a-input v-model.trim="searchData.bk_sort" allowClear placeholder="day_sort" style="width: 180px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item class="block-line">
              <!-- <a-date-picker @change="onChange" allowClear v-model="searchData.created_at" /> -->
              <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
              <a-button type="primary" icon="reload" @click="updateFs">刷新</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
          :columns="columns" @change="handleChange" :dataSource="list">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="opt_content" slot-scope="text">
              <div v-html="text"></div>
            </template>
            <template slot="bk_name" slot-scope="text,record">
              <a :href="'https://quote.eastmoney.com/bk/'+record.f13 +'.'+record.f12+'.html'" target="_blank">{{text}}</a>
            </template>
            
            <template slot="stock_name" slot-scope="text, record , index">
                <span @click.stop v-clipboard:copy="record.stock_code" >{{text}}</span>
                <a-tag color="red" v-for="(data,index) in record.stock_short_tag" :key="index">
                  {{data}}
                </a-tag>
            </template>
            <template slot="stock_code" slot-scope="text, record , index">
                <a-badge :count="record.stock_kick_count" :offset=[15,0] :number-style="{
        backgroundColor: '#fff',
        color: 'red',
        boxShadow: '0 0 0 1px #d9d9d9 inset',
      }">
                  <span @click="clk(record.stock_code,record)">{{text}}</span>
                </a-badge>
            </template>
            <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
            </template>
            <template slot="B" slot-scope="text, record , index">
                <span>{{record.stock_bsm.rocket_buy}}</span>
            </template>
            <template slot="stock_chengjiaocha" slot-scope="text, record , index">
              <a-progress :percent="text" size="small" :show-info="false" />
            </template>
            <template slot="S" slot-scope="text, record , index">
                <span>{{record.stock_bsm.rocket_sell}}</span>
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>

      <a-modal v-model="editVisible" :title="stock_title" cancelText="取消" okText="刷新" width="1550px" @ok="handleOk" >
        <vue-element-loading :active="loadingP" color="#00cca2" spinner="spinner"/>
          <a-date-picker @change="onFsChange" />
            <ve-histogram 
            :data="predictChart" 
            v-if="false"
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`${predictChart.title}`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['M'],
                axisSite: { right: ['M'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

            <ve-histogram 
            :data="predictLiuruChart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`资金流入统计`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['M'],
                axisSite: { right: ['M'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                :columns="columns2" :dataSource="liuruList">
                <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1)}}</span>
                </template>
                <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
                </template>
            </a-table>
            <template>
        </template>
        <LEmpty v-if="first>0 && predictChart.rows.length == 0"/>
      </a-modal>

      <a-modal v-model="fsVisible" cancelText="取消" okText="刷新" width="1550px">
        <ve-histogram 
            :data="fs_list_chart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['P','A'],
                axisSite: { right: ['P','A'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

      </a-modal>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' ,sorter:()=>{}},
  { title: '板块', width: 80, align:'center', dataIndex: 'f14', key: 'f14',sorter:()=>{}, scopedSlots: { customRender: 'bk_name' }},
  { title: '涨幅', width: 80, align:'center', dataIndex: 'f3', key: 'f3',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '流入金额',  align:'center', dataIndex: 'f62', key: 'f62',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  
  { title: '流入股票',  align:'center', dataIndex: 'f204', key: 'f204',sorter:()=>{}},
  { title: '流入最大',  align:'center', dataIndex: 'f205', key: 'f205',sorter:()=>{}},
  { title: '板块', align:'center', dataIndex: 'f14', key: 'f142222'},
  { title: 'time', align:'center', dataIndex: 'updatetime', key: 'updatetime'},
]

const columns2 = [
  // { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' },
  { title: '日期', align:'center', dataIndex: 'stock_date', key: 'stock_date'},
  { title: '开盘', align:'center', dataIndex: 'stock_jinkai', key: 'stock_jinkai'},
  { title: '价格', align:'center', dataIndex: 'stock_now', key: 'stock_now'},
  { title: '振幅', align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu'},
  { title: '盘口', align:'center', dataIndex: 'stock_pankou', key: 'stock_pankou'},
  { title: '涨幅', align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu', scopedSlots: { customRender: 'price' }},
  { title: '成交额', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe' , scopedSlots: { customRender: 'price' }},
  { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' }},
  { title: '流入占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '超大流入', align:'center', dataIndex: 'stock_chaoda_liuru', key: 'stock_chaoda_liuru', scopedSlots: { customRender: 'price' }},
  { title: '超大占比', align:'center', dataIndex: 'stock_chaoda_liuru_zhanbi', key: 'stock_chaoda_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '大流入', align:'center', dataIndex: 'stock_da_liuru', key: 'stock_da_liuru', scopedSlots: { customRender: 'price' }},
  { title: '大占比',  align:'center', dataIndex: 'stock_da_liuru_zhanbi', key: 'stock_da_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '中流入', align:'center', dataIndex: 'stock_zhong_liuru', key: 'stock_zhong_liuru', scopedSlots: { customRender: 'price' }},
  { title: '中占比', align:'center', dataIndex: 'stock_zhong_liuru_zhanbi', key: 'stock_zhong_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '小流入', align:'center', dataIndex: 'stock_xiao_liuru', key: 'stock_xiao_liuru', scopedSlots: { customRender: 'price' }},
  { title: '小占比', align:'center', dataIndex: 'stock_xiao_liuru_zhanbi', key: 'stock_xiao_liuru_zhanbi', scopedSlots: { customRender: 'price' }}

]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'operation',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
  },

  data() {
    this.chartSettings = {
        xAxisType: ['KMB', 'percent'],
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [],
          label:{
              position:'insideStartTop'
          }
      },
      this.tooltip = {
          show:true,  
          trigger: "axis",
          alwaysShowContent:true,
          formatter: function(params) { 
            var res;
            console.log(params)
            return res;
          }
        },
      this.chartStockSettings = {
        yAxis:{
          show:false,
        },
        grid: {
          containLabel: true,
        },
        showDataZoom: false,
        start: 0,
        end: 100,
        showMA: false,
        MA: [5,10],
        legendName: {}, 
        showVol: false,
        upColor: '#FF8787',
        downColor: '#7FB77E',
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [],
          label:{
              show: false, //隐藏标示文字
              position:'insideEndTop'
          }
      },
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
    return {
      loading: false,
      fsVisible:false,
      first:0,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      fs_list:[],
      fs_list_chart:[],
      stock_title:'',
      stock_detail:{},
      columns,
      columns2,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      operationTypes: C_ITEMS.operationTypes,
      searchData: {
        shsz:'sz',
        bk_sort:7,
      },
      visible: false,
      editVisible:false,
      refundVisible: false,
      exportLoading: false,
      histogramChartExtend:{
        series(item){
            console.log(item)
                if(item){
                    item[0].barMaxWidth = 10
                    item[0].color='#23d9ae'

                    if(item[1]){
                      item[1].color='red'
                    }

                    if(item[3]){
                      item[3].color='#FF6600'
                    }
                }
                return item
            },
            xAxis:{
                axisLine:{
                    show:true,
                    lineStyle: {
                        type: 'dotted',
                    }
                },
            },
            yAxis(item){
                item[0].axisLine = Object.assign({}, {
                    show:false
                })
                item[0].splitLine = Object.assign({}, {
                    show:true,
                    lineStyle: {
                        type: 'dotted'
                    }
                })
                return item
            },
        },
        pie:{},
        stockCode:'',
        predictMsg:[],
        stockChartData:[],
        dailyChart:[],
        predictChart:[],
        predictLiuruChart:[],
        loadingP:false,
        liuruChart:[],
        liuruList:[],
    }
  },
  mixins: [ ranges, tableMixins ],
  created() {
    this.searchData.created_at =[moment().subtract('days',0).format('YYYY-MM-DD'),moment().subtract('days',0).format('YYYY-MM-DD')]
  },
  methods: {
    updateFs(){
      console.log('test');
    },
    handleButChange(e){
        this.searchData.shsz = e.target.value
        this.getList()
    },
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onFsChange(date, dateString) {
        this.paramsData.search.stock_date = dateString
        this.getPredictChart(this.stockCode);
    },
    clkFs(data){
        this.fsVisible = true;
        this.fs_list = data.sub_fs;
        this.fs_list_chart = data.sub_fs_chart;
    },
    closeSearch() {
      this.visible = false
    },
    async handleOk() {
        this.getPredictChart(this.stockCode)  
    },
    clk(stock_code,stock_detail){
      this.stockCode = stock_code;
      this.stock_detail = stock_detail;
      this.getPredictChart(stock_code)
    },
    async getPredictChart(stock_code) {
      this.editVisible = true;
      this.loadingP = true;
      let res = await this.$store.dispatch('enterpriseLabLiuruAction', {data:{search:{stock_name:stock_code,stock_updatetime:this.stock_detail.stock_updatetime}}})
      this.predictChart = res.data.predict_chart
      this.predictLiuruChart = res.data.predict_liuru_chart
      this.pie = res.data.pie
      this.stock_title =  this.stock_detail.stock_name + ' ' + res.data.pie_title
      this.liuruList = res.data.liuru_list
      this.predictMsg = res.data.fs_list
      this.fs_list_chart = res.data.sub_fs_chart;
      this.loadingP = false
    },
    color(value){
        if(value < 0){
          return {color:'green'};
        }
        if(value==0){
          return '';
        }
        if(value > 0){
          return {color:'red'};
        }
    },
    formatNumber(value) {
      let flag = '';
      if(value<0){
        value=Math.abs(value)
        flag = '-';
      }
      if (value >= 100000000) {
        return flag+(value / 100000000).toFixed(2) + ' 亿';
      } else if (value >= 10000) {
        return flag+(value / 10000).toFixed(2) + ' 万';
      } else if (value >= 1000) {
        return flag+(value / 1000).toFixed(2) + ' 千';
      } else {
        return flag+value;
      }
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            console.log(sorter);
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.column.key}`
            }else{
                this.searchParams['sort'] = `-${sorter.column.key}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList()
    },
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('enterpriseLabStockBkListAction', { data: this.searchParams })
      console.log(this.searchParams);
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-content {
  white-space: normal; /* 允许换行 */
  word-wrap: break-word; /* 长单词换行 */
}
.ant-modal-body .ant-row{
    margin: 10px 20px !important;
}
</style>