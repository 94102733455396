import Vue from 'vue'
import { enterprise,enterpriseRule,enterpriseRuleTypes,enterpriseRuleAdd,enterpriseUpdate,enterpriseCompany,enterpriseCompanyAdd,enterpriseCompanyUPdate,enterpriseFiscal,enterpriseFiscalAdd,enterpriseFiscalItem,enterpriseFiscalDel,
  enterpriseCompanyModule,enterpriseCompanyEnableModule,enterpriseCompanyDisableModule,enterpriseMarket,enterpriseMarketAdd,enterpriseMarketUPdate,enterpriseLabIndex,enterpriseLoggingAccess,enterpriseStatisticsNotification,
  enterpriseStatistics,enterpriseStatisticsCount,enterpriseStatisticsPayment,enterpriseMonitorImage,enterpriseStatisticsDailyActive,enterpriseAccountLive,enterpriseCompanyStatus,enterpriseStatisticsDailyUserHeatmap,enterpriseStatisticsDailyEmployeeHeatmap,
  enterpriseSystemClean,enterpriseLogging,enterpriseStatisticsBankType,enterpriseStatisticsFinance,enterpriseAccountIndex,enterpriseLabStock,enterpriseLabStockList,enterpriseAccountStudentAccount,enterpriseCompanyClean,enterpriseUpgradeLog,enterpriseLoggingErrReport,enterpriseLoggingFlushErrReport,enterpriseMonitorIndex,
  enterpriseUpgradeLogAdd,enterpriseUpgradeLogUpdate,enterpriseLabLiuru,enterpriseLabDetail,enterpriseLabStockShortList,enterpriseLabStockBkList,enterpriseLabStockShootDel,enterpriseLabStockKickTree,enterpriseLabStockRelay,enterpriseUpgradeLogDetail,enterpriseStatisticsBook,enterpriseLabStockOpen,enterpriseLabStockShoot,enterpriseAccountLiveStudent,enterpriseMonitorAdd,enterpriseMonitorUpdate} from '@/api/enterprise'
import config from '@/utils/const'


export default {
  state: {

  },
  mutations: {
  },
  actions: {
    enterpriseAction({ commit }, params) {
        return new Promise((resolve,reject) => {
            enterprise(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
          reject(reason)
          })
        })
    },

    enterpriseRuleAction({ commit }, params) {
        return new Promise((resolve,reject) => {
          enterpriseRule(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
          reject(reason)
          })
        })
    },

    enterpriseRuleTypesAction({ commit }, params) {
        return new Promise((resolve,reject) => {
            enterpriseRuleTypes(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
          reject(reason)
          })
        })
    },

    enterpriseRuleAddAction({ commit }, params) {
        return new Promise((resolve,reject) => {
            enterpriseRuleAdd(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
          reject(reason)
          })
        })
    },

    enterpriseUpdateAction({ commit }, params) {
        return new Promise((resolve,reject) => {
            enterpriseUpdate(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
          reject(reason)
          })
        })
    },

    enterpriseCompanyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompany(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseCompanyAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    enterpriseCompanyUPdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyUPdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseCompanyModuleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyModule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseCompanyStatusAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyStatus(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseCompanyCleanAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyClean(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseCompanyEnableModuleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyEnableModule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseCompanyDisableModuleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseCompanyDisableModule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseMarketAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMarket(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseMarketAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMarketAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseMarketUPdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMarketUPdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },


    enterpriseLabStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockBkListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockBkList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockOpenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockOpen(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockShootAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockShoot(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockShootDelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockShootDel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockShortListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockShortList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockRelayAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockRelay(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabStockKickTreeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabStockKickTree(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },


    enterpriseLabIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabLiuruAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabLiuru(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLabDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLabDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    

    enterpriseStatisticsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatistics(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseStatisticsCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseStatisticsPaymentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsPayment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseStatisticsDailyActiveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsDailyActive(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseStatisticsBankTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsBankType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseStatisticsFinanceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsFinance(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseStatisticsBookAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsBook(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseStatisticsNotificationAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsNotification(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseStatisticsDailyUserHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsDailyUserHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseStatisticsDailyEmployeeHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseStatisticsDailyEmployeeHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseAccountLiveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseAccountLive(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseAccountIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseAccountIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseMonitorIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMonitorIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseMonitorImageAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMonitorImage(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseFiscalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseFiscal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    enterpriseFiscalItemAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseFiscalItem(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },


    enterpriseFiscalAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseFiscalAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    enterpriseFiscalDelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseFiscalDel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },


    enterpriseMonitorAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMonitorAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    enterpriseMonitorUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseMonitorUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    
    enterpriseAccountLiveStudentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseAccountLiveStudent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    enterpriseAccountStudentAccount({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseAccountStudentAccount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    enterpriseSystemCleanAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseSystemClean(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseLoggingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLogging(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLoggingAccessAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLoggingAccess(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLoggingFlushErrReportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLoggingFlushErrReport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },

    enterpriseLoggingErrReportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseLoggingErrReport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseUpgradeLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseUpgradeLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseUpgradeLogAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseUpgradeLogAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseUpgradeLogUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseUpgradeLogUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    
    enterpriseUpgradeLogDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseUpgradeLogDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
        reject(reason)
        })
      })
    },
    

  }
}
